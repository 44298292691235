@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
.random-btn{
    border:none;
    background:none;
}

.random-btn:hover{
    cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* The background styles */
  min-height: 100vh;
  background-image: linear-gradient(
      124.51deg,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url(https://cdn0.gamesports.net/storage/118000/118805.jpg);
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: 100%;
/*   overflow: hidden; */

  /* animation: moveRightToLeft 10s linear 0s infinite alternate; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

@-webkit-keyframes moveRightToLeft {
  0% {
    background-position-x: 0%;
  }

  100% {
    background-position-x: 100%;
  }
}

@keyframes moveRightToLeft {
  0% {
    background-position-x: 0%;
  }

  100% {
    background-position-x: 100%;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 24px;
  }
  body {
    background-size: 200%;
  }
}

@media (max-width: 900px) {
  html {
    font-size: 18px;
  }
  body {
    background-size: 250%;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 13px;
  }
  body {
    background-size: 350%;
    height: auto;
    overflow: auto;
  }
  .no-scroll {
    height: auto;
  }
}

